<template>
  <div class="deliver">
    <!-- deliverresume -->
    <van-form @submit="onSubmit" enctype="multipart/form-data">
      <van-field
        v-model="ruleForm.name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="ruleForm.school"
        name="学校"
        label="学校"
        placeholder="学校"
        :rules="[{ required: true, message: '请填写学校' }]"
      />
      <van-field
        v-model="ruleForm.professional"
        name="专业"
        label="专业"
        placeholder="专业"
        :rules="[{ required: true, message: '请填写专业' }]"
      />
      <van-field
        name="file"
        label="上传简历"
        :rules="[{ required: true, message: '请上传简历' }]"
      >
        <template #input>
          <van-uploader
            v-model="fileList"
            :after-read="afterRead"
            accept="image/*"
            multiple
          />
        </template>
      </van-field>
      <!-- <van-cell title="上传简历" /> -->

      <div style="margin: 16px">
        <van-button
          style="background-color: #f5e82a; border: none"
          round
          :loading="loading"
          block
          type="info"
          native-type="submit"
          loading-text="提交中..."
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { sendResume } from '@/api/livebroadcast';
import { Form, Field, Button, Uploader, Cell, Dialog } from 'vant';

export default {
  data() {
    return {
      ruleForm: {
        name: '',
        professional: '',
        file: [],
        school: '',
      },
      fileList: [],
      loading: false,
    };
  },
  components: {
    [Image.name]: Image,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Cell.name]: Cell,
  },
  methods: {
    afterRead(file) {
      console.log(file); //file文件如下图
      this.ruleForm.file = file.file;
      // var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      // formData.append('file', file.file); //接口需要传的参数
      // this.$upload('/single/single/upload', formData).then((res) => {
      //   //将formDate文件上传到阿里云服务器，会返回图片地址
      //   console.log(res, 111111);
      //   let str = res.response_data[0];
      //   this.uploadImages.push(str); //将图片地址存入一个数组中
      // });
    },
    onSubmit() {
      const { company, position, liveAnchorLiveId } = this.$route.query;
      let formData = new FormData();
      formData.append('file', this.ruleForm.file);
      formData.append('school', this.ruleForm.school);
      formData.append('name', this.ruleForm.name);
      formData.append('professional', this.ruleForm.professional);
      formData.append('company', company);
      formData.append('position', position);
      formData.append('liveAnchorLiveId', liveAnchorLiveId);
      this.loading = true;
      sendResume(formData)
        .then((res) => {
          console.log(res);
          const data = res.result;
          this.loading = false;
          if (data) {
            Dialog.alert({
              message: '已为您成功投递一份简历！',
            }).then(() => {
              this.$router.back();
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.deliver {
  .el-form-item__label {
    font-size: 28pt !important;
    line-height: 80px;
  }
  .el-form-item {
    margin-bottom: 44px;
  }
  .el-input__inner {
    height: 80px !important;
    line-height: 80px !important;
  }
  .el-input {
    font-size: 28px;
    width: 90%;
  }
  .el-button--primary {
    background-color: #f5e929 !important;
    border-color: #f5e929;
    color: #000;
    padding: 20px 100px;
  }
}
</style>
<style lang="scss" scoped>
.deliver {
  background-color: white;
  .header {
    height: 120pt;
    text-align: center;
    background-color: white;
    font-size: 32pt;
    font-weight: 800;
    line-height: 120px;
  }
  .submit {
    text-align: center;
    font-size: 24pt;
    margin-left: 25%;
  }
}
</style>
